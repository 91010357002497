<template>
  <div>
    <!-- Form: Inventory Form -->
    <b-row class="mt-2">
      <b-col>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          v-b-modal.modal-receipt
        >
          Tambah
        </b-button>
      </b-col>
    </b-row>
      <!-- TABLE -->
      <b-row>                   
          <b-card
            no-body
            class="border mt-1"
          >
            <b-card-header class="p-1">
              <b-card-title class="font-medium-2">
                <feather-icon
                  icon="AlignJustifyIcon"
                  size="18"
                />
                <span class="align-middle ml-50">List</span>
              </b-card-title>
            </b-card-header>
            <b-table
              fixed
              class="mb-0"
              :fields="field" 
              :items="receipts"
            >
              
              <template #cell(created_at)="data">
                {{ new Date(data.item.created_at).toString().slice(4,15) }}
              </template>
              <template #cell(id)="data">
                  <div class="text-nowrap">
                  <!-- <feather-icon
                      :id="`receipt-row-${data.item.id}`"
                      v-b-modal.modal-receipt
                      @click="editSupply(data.item.id)"
                      icon="EditIcon"
                      class="cursor-pointer"
                      size="20"
                  />
                  <b-tooltip
                      title="Edit Receipt"
                      class="cursor-pointer"
                      :target="`receipt-row-${data.item.id}`"
                  /> -->

                  <feather-icon
                      @click="showMsgBoxRemove(data.item.id)"
                      :id="`receipt-row-${data.item.id}`"
                      icon="TrashIcon"
                      size="20"
                      class="mx-2 cursor-pointer"
                  />
                  <b-tooltip
                      title="Delete Receipt"
                      class="cursor-pointer"
                      :target="`receipt-row-${data.item.id}`"
                  />
                  </div>
              </template>
            </b-table>
          </b-card>

          <b-col cols="12">
            <hr>
          </b-col>
      </b-row>

      <!-- Form -->
      <b-modal
        id="modal-receipt"
        ref="modal-receipt"
        title="Memasok"
        hide-footer
        @hidden="resetModalReceipt"
      >
        <div class="d-block">
          <b-form class="mt-1">
          
          <div role="group">
            <label for="input-live">Kuantitas</label>
            <b-form-input
              id="quantity"
              type="number"
              v-model="quantity"
            />
          </div>

          <div role="group">
            <label for="input-live">Satuan</label>
            <v-select
                v-model="unit"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="units"
                :reduce="(option) => option.id"
                label="title"
              />
          </div>

          <div role="group">
            <label for="input-live">Deskripsi</label>
              <b-form-textarea
                id="notes"
                placeholder="Deskripsi"
                rows="2"
                v-model="description"
              />
          </div>
      </b-form>
          
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-3"
          variant="primary"
          block
          @click="created"
        >
          {{ $t('Create') }}
        </b-button>        
      </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BInputGroup, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton, BInputGroupAppend, BInputGroupPrepend, 
  BFormTextarea, BModal, BTable, BCard, BCardHeader, BCardTitle, BAvatar, VBModal,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import moduleApi from '@/store/module'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BInputGroup, BFormGroup, 
    flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton, BInputGroupAppend, BInputGroupPrepend, BFormTextarea, BModal,
    BTable, BCard, BCardHeader, BCardTitle, BAvatar
  },
  directives: {
    'b-modal': VBModal,
  },
  data: () => ({
    id: '',
    quantity: '',
    unit: '',
    description: '',
    product_name: '',
    pageLength: 10,
    field: [
      { key: 'quantity', label: 'Kuantitas' },  
      { key: 'title', label: 'Satuan' },       
      { key: 'notes', label: 'Deskripsi' },
      { key: 'created_at', label: 'In' },
      { key: 'id', label: 'Tindakan' },
    ],
    receipts: [],   
    receipt: {},
    warehouse: JSON.parse(localStorage.getItem("warehouse")),
    units: [],
  }),
  methods: {
    fetchDataListWarehouse(id) { 
      moduleApi.fetchDataProducsById(id)
        .then(response => {
            let product  = response.data.data
            this.product_name = product.name
            this.fetchDataListUnit(product.id_unit_group)
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchDataListUnit(id_unit_group) { 
      moduleApi.fetchDataListUnitsByGroup(id_unit_group)
        .then(response => {
            this.units = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchDataListReceipt(id_product) {
      moduleApi.fetchDataListReceipt(id_product, this.warehouse.id)
        .then(response => {       
            this.receipts  = response.data.data
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
    created() {
        if(this.id === ''){
            moduleApi.receiptProduct({
              product_name: this.product_name,
              quantity: this.quantity,
              unit: this.unit,
              notes: this.description,
              id_product: this.$route.params.id,
              warehouse_id: this.warehouse.id,
              warehouse_name: this.warehouse.name,
              transaction_type: 'FROM_WAREHOUSE'
            })
            .then(response => {
                this.fetchDataListReceipt(this.$route.params.id)
                this.quantity = ''
                this.description = ''
                this.unit = ''
                this.$refs['modal-receipt'].toggle('#toggle-btn')
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Announcement`,
                  icon: 'InfoIcon',
                  variant: 'danger',
                  text: `${error}`,
                },
              })
            })
        } else {
          moduleApi.updateReceipt({
            quantity: this.quantity,
            unit: this.unit,
            notes: this.description,
            id: this.id
          })
          .then(response => {
              this.fetchDataListReceipt(this.$route.params.id)
              this.id = ''
              this.quantity = ''
              this.description = ''
              this.unit = ''
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Announcement`,
                icon: 'InfoIcon',
                variant: 'danger',
                text: `${error}`,
              },
            })
          })
        }       
    },
    editSupply(id) {
      moduleApi.fetchDataSupplyById(id)
        .then( response => {
            this.receipt = response.data.data
            this.id = this.receipt.id
            this.quantity = this.receipt.quantity
            this.description = this.receipt.notes
            this.unit = this.receipt.unit
        })
        .catch( error => {
          console.log(error)
        })
    },
    showMsgBoxRemove(id) {
      this.$bvModal
        .msgBoxConfirm('Konfirmasikan bahwa Anda ingin menghapus data ini.', {
          title: 'Konfirmasi',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Ya',
          cancelTitle: 'Tidak',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            this.handleRemove(id)
          }
        })
    },
    handleRemove (id) {
      moduleApi.deletedSupplying(id)
        .then( response => {
            this.fetchDataListReceipt(this.$route.params.id)
        })
        .catch( error => {
          console.log(error)
        })
    },
    resetModalReceipt () {
      this.quantity = ''
      this.description = ''
      this.unit = ''
    }
  },
  async created () {
    this.fetchDataListWarehouse(this.$route.params.id)
    this.fetchDataListReceipt(this.$route.params.id)
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
