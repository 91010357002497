const BASE_URL_API_DISTRIBUTION = `${process.env.VUE_APP_DISTRIBUTION_API}`
export default class DistributionApi {
  _fetchWithAuth(url, options = {}) {
      return fetch(url, {
        ...options,
        mode: 'cors',
        headers: {
          ...options.headers,
        },
      });
  }

  async createDistributionItem(...params) {
    const response = await this._fetchWithAuth(`${BASE_URL_API_DISTRIBUTION}/v1.0/api/items/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(...params),
    })
    const responseJson = await response.json()
    return responseJson
  }

  async createDistributionTransaction(...params) {
    const response = await this._fetchWithAuth(`${BASE_URL_API_DISTRIBUTION}/v1.0/api/transactions/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(...params),
    })
    const responseJson = await response.json()
    return responseJson
  }

  async createDistributionReceivers(...params) {
    const response = await this._fetchWithAuth(`${BASE_URL_API_DISTRIBUTION}/v1.0/api/receivers/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(...params),
    })
    const responseJson = await response.json()
    return responseJson
  }

  async fetchDistributionHistory(distribution_id) {
    const response = await this._fetchWithAuth(`${BASE_URL_API_DISTRIBUTION}/v1.0/api/transactions/search/?items_id=${distribution_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
    const responseJson = await response.json()
    return responseJson
  }
}